<script>
	let promise = fetchFaucets();
	let filter;
	// Faucets come in sorted by oldest to newest timestamp
	async function fetchFaucets(){	
		let faucets = await fetch("https://runningfaucets-server-bu2xp.ondigitalocean.app/faucets/").then(r => r.json());
		return faucets;
	}

</script>

<main>
	<div class="logo">
		<img src="/images/eth-diamond-purple.png" alt="Ethereum Diamond Logo">
		<h2>Running Faucets</h2>
	</div>
	{#await promise}
		<div style="height: 50vh;">
			<h2>Loading...</h2>
		</div>
		{:then faucets}
		<div class="faucet-container">
			
			<label for="networkFilter">Filter Network:</label>
			<select id="networkFilter" bind:value={filter}>
				<option value="all">All</option>
				<option value="rinkeby">Rinkeby</option>
				<option value="ropsten">Ropsten</option>
				<option value="kovan">Kovan</option>
				<option value="goerli">Goerli</option>
			</select>
			<p>Sorted by most recent payout</p>
		{#each faucets as faucet}
		{#if faucet.network == filter || filter == "all"}
		<div class="faucet">
			<div class="faucet-url">
				<h2><a href={faucet.url} target="_blank">{faucet.url}</a></h2>
				<span class="network">{faucet.network.toUpperCase()}</span>
			</div>

			<p><a class="etherscan" href={`https://${faucet.network}.etherscan.io/address/${faucet.address}`} target="_blank">{faucet.address}</a></p>
			<p>Balance: {faucet.balance} ETH</p>	
			<p>Last Payout: {faucet.formattedTimestamp.date} at {faucet.formattedTimestamp.time} GMT</p>
		</div>
		{/if}
		{/each}
	</div>
		
	{:catch}
		<div style="height: 50vh;">
			<h2 style="color: red;">Could not retrieve data from the server. Please try again later.</h2>
		</div>
	{/await}


</main>

<footer style="text-align: center; margin-bottom: 1rem;">
	<a href="https://etherscan.io/" target="_blank">Powered by Etherscan.io APIs</a> <br style="margin: 1rem 0rem;">
	<b>Support me by donating: </b>
	<p>tankyroo.eth</p>
	<p>0x4836a8C7a9a45165383C482818dD34645b7FD9A0</p>
	<img src="/images/qr.png" alt="0x4836a8C7a9a45165383C482818dD34645b7FD9A0"/>
</footer>

<style>
	.faucet-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 500px;
		margin: 0 auto;
	}
	.faucet{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		margin: 1rem;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: rgba(1, 14, 40, 0.08) 0px 8px 30px -5px;
	}

	.faucet:hover{
		box-shadow: rgba(1, 14, 40, 0.15) 0px 12px 30px -5px;
		transition: box-shadow 0.2s ease 0s;
	}

	.faucet .faucet-url{
		width: 100%;
		display: flex;
		align-items: flex-start;
		align-items: center;		
		justify-content: space-between;
	}

	.logo {
		display: flex;
		align-items: center;
	}

	.logo img {
		width: 16px;
		height: fit-content;
		margin: 0 1rem;
	}

	main {
		text-align: center;
		width: 100%;
	}

	p, a, .network {
		color: #282c34;
		word-break: break-word;
	}

	.etherscan {
		text-decoration: underline;
	}


	@media (max-width: 640px) {
		.faucet{
			width: 75%;
		}

		.faucet-url {
			flex-direction: column;
		}
		a {
			word-wrap: anywhere;
		}
	}
</style>